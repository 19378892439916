.home {
  color: #05283a;
  background-image: url('./bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  position: relative;
}

.home-text {
  display: flex;
  flex-direction: column;
  margin-top: 340px;
  color: #f2f0e3;
  align-items: center;
}

.home-user {
  font-size: 12px;
  font-weight: 300;
}

.home-title {
  font-size: 120px;
  margin-top: 20px;
}

.home-description {
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  width: 400px;
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 900px;
  color: #f2f0e3;
}

.footer > a {
  color: #f2f0e3;
  padding: 10px;
}
