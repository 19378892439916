/* TODO Add site wide styles */
* {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  background-color: #f2f0e3;
  color: #20282b;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
  color: #20282b;
}

input {
  padding: 10px 14px 10px 14px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  background-color: #e7e4d2;
  outline: none;
}

button {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 10px 14px 10px 14px;
  color: #f2f0e3;
  background-color: #6f985e;
  cursor: pointer;
}

form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
}
