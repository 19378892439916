.setlist-index-container {
  width: 75%;
  margin: auto;
}

.setlist-index-create-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.setlist-index-content-container {
  display: flex;
  border-top: solid 1px #20282b;
  padding: 20px;
}

.setlist-index-setlists {
  display: flex;
  flex-direction: column;
}

.setlist-index-setlist {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.setlist-index-setlist > a {
  margin-left: 20px;
}

.setlist-index-setlist > .setlist-index-setlist:hover {
  background-color: #d1d1d1;
}

.setlist-index-setlist.active {
  background-color: #d1d1d1;
}

.setlist-index-props {
  display: flex;
  flex-wrap: wrap;
}
