.propshow-background {
  background: rgb(149, 182, 135);
  background: linear-gradient(
    180deg,
    rgba(149, 182, 135, 1) 50%,
    rgba(156, 193, 140, 1) 50%
  );
  padding: 80px 150px 80px 150px;
}

.propshow-main {
  background-color: #f2f0e3;
  display: flex;
  margin: auto;
  width: 75%;
  position: relative;
}

.propshow-back-to-all {
  position: absolute;
  top: -50px;
}

.propshow-button-back-to-all {
  background-color: #f2f0e3;
  width: 200px;
  font-weight: 600;
  color: #20282b;
}

.propshow-text {
  padding: 60px;
  flex-grow: 1;
}

.propshow-name {
  font-size: 28px;
  font-family: 'Lalo';
  margin-bottom: 10px;
}

.propshow-price {
  margin-bottom: 10px;
  font-size: 18px;
}

.propshow-availability {
  margin-bottom: 40px;
}

.propshow-setlist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

.propshow-select {
  margin-bottom: 10px;
}

.propshow-button {
  background-color: #6f985e;
  border-radius: 0;
  color: #f2f0e3;
  width: 200px;
  font-weight: 600;
}

.propshow-description {
  margin-top: 40px;
  margin-left: 300px;
  line-height: 2;
  margin-bottom: 20px;
}

.propshow-prophouse {
  margin-top: 20px;
  margin-left: 300px;
  margin-bottom: 20px;
}

.propshow-prophouse-link:hover {
  color: #6f985e;
  text-decoration: underline;
}

.prop-show-edit-buttons {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  column-gap: 10px;
  top: -50px;
  left: 10px;
}

.prop-show-edit-buttons button {
  background-color: #f2f0e3;
  color: #20282b;
}
