.profile-dropdown {
  position: absolute;
  z-index: 1;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  background-color: #f2f0e3;
  border: 1px solid #20282b;
  color: #20282b;
}

.profile-dropdown > *:not(:first-child) {
  margin-top: 10px;
}

.profile-dropdown > button {
  background-color: #f2f0e3;
  color: #20282b;
  border-radius: 5px;
}

.hidden {
  display: none;
}

.navbar {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 40px;
}

.navbar-logo {
  font-size: 30px !important;
}

.navbar-links {
  display: flex;
  align-items: center;
  width: 25%;
  justify-content: space-between;
}

.navbar a {
  color: #f2f0e3;
  font-size: 14px;
}

.profile-button {
  color: #f2f0e3;
  font-size: 14px;
  cursor: pointer;
}

.navbar-holder a {
  color: #05283a;
}

.navbar-holder .profile-button {
  color: #05283a;
}
