.signup-page-container {
  display: flex;
  box-sizing: border-box;
  height: 100vh;
}

.signup-content {
  width: 50%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  font-size: 20px;
  font-weight: 500;
}

.signup-title {
  font-size: 24px;
}

.signup-button-container {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.signup-button-container > button {
  width: 50%;
}

.signup-image-container {
  width: 50%;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  background-image: url(https://hips.hearstapps.com/hmg-prod/images/9b897581-5909-490c-bc6c-70bba8dff638-jpg-1624646953.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*);
}

.signup-form {
  margin-top: auto;
  margin-bottom: auto;
}
